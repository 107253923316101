<template>
  <div class="logs-wrap">
    <div class="title">登录记录</div>
    <div class="tips">
      <span>以下是您最近3个月的登录记录</span>
      <span>
        ，若存在异常登录记录，请尽快
        <router-link to="/user/password">修改密码</router-link>
      </span>
    </div>
    <div class="table-wrapper">
      <el-table
        cell-class-name="custom-table-cell logs-table-cell"
        header-row-class-name="custom-table-header logs-table-header"
        :data="logsState.list"
        ref="tableRef"
        empty-text="暂无数据"
        v-loading="logsState.loadingState"
      >
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="createTime"
          label="登录时间"
          width="180"
        ></el-table-column>
        <el-table-column align="center" show-overflow-tooltip prop="depName" label="登录方式" width="120">
          <template #default="scope">
            <span>{{ scope?.row?.loginMethod?.desc || '' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ip" width="160" label="来源IP"></el-table-column>
        <!-- TODO 暂无参考地点 -->
        <el-table-column align="center" show-overflow-tooltip prop="address" label="参考地点"></el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template #default="scope">
            <!-- TODO 暂无详细分类 -->
            <span v-if="scope?.row?.remark && scope?.row?.remark.indexOf('成功') !== -1" class="status-success">
              成功
            </span>
            <span v-else class="status-fail">失败</span>
            <!-- <span v-if="scope.row" class="status-error">异常</span> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination-block
      v-if="logsState.count > 10"
      @page-change="handleCurrentChange"
      v-model="logsState.query.pageNo"
      :page-size="logsState.query.pageSize"
      @size-change="handleSizeChange"
      :total="logsState.count"
    ></pagination-block>
  </div>
</template>
<script>
import { reactive, onMounted } from 'vue'
import { useLoginLogs } from '@/hooks/sys'
import PaginationBlock from '@/components/pagination'
export default {
  components: { PaginationBlock },
  name: 'logs',
  props: {},
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const state = reactive({
      jumperPage: ''
    })
    const { logsState, initLoginLogsList } = useLoginLogs(true)
    const handleCurrentChange = page => {
      logsState.query.pageNo = page
      initLoginLogsList()
    }
    const handleSizeChange = size => {
      logsState.query.pageSize = size
      logsState.query.pageNo = 1
      initLoginLogsList()
    }
    const jumperClick = () => {
      if (!state.jumperPage) return false
      logsState.query.pageNo = state.jumperPage - 0 || 1
      initLoginLogsList()
    }
    onMounted(() => {})
    return {
      state,
      logsState,
      handleCurrentChange,
      handleSizeChange,
      jumperClick
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
