<template>
  <div class="pagination-block">
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="currentPage"
      @size-change="handleSizeChange"
      :page-size="pageSize"
      layout="total,prev,pager,next,sizes,slot"
      prev-text="上一页"
      next-text="下一页"
      :background="true"
      size="mini"
      :pager-count="5"
      :total="total"
    >
      <div class="dumper">
        <label>到</label>
        <el-input size="mini" @keyup.enter="jumperClick" v-number:[maxTotalPage] v-model="jumperPage"></el-input>
        <label>页</label>
        <button class="jumper-btn" @click="jumperClick">确认</button>
      </div>
    </el-pagination>
  </div>
</template>
<script>
import { computed, ref } from 'vue'
export default {
  name: 'paginationBlock',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 40, 50]
    },
    modelValue: {
      type: Number,
      default: 1
    }
  },
  emits: ['page-change', 'size-change', 'update:modelValue'],
  setup(props, { emit }) {
    const currentPage = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    const jumperPage = ref('')
    const handleCurrentChange = page => {
      currentPage.value = page
      emit('page-change', page)
    }
    const handleSizeChange = size => {
      emit('size-change', size)
    }
    const maxTotalPage = computed(() => {
      return Math.ceil(props.total / props.pageSize)
    })
    const jumperClick = () => {
      if (!jumperPage.value) return false
      let value = +jumperPage.value
      if (value > maxTotalPage.value) {
        value = maxTotalPage.value
      }
      if (value === 0) {
        value = 1
      }
      handleCurrentChange(value)
    }

    return {
      currentPage,
      jumperPage,
      jumperClick,
      handleSizeChange,
      handleCurrentChange,
      maxTotalPage
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
